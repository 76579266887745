import React from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Layout from '../components/layout';
import HeadHelmet from '../components/head';
import { StyledBackground } from '../components/ui-elements/backgroundImage';

const NewsSlug = ({ data }) => {
  const { strapiSsNews } = data;
  return (
    <>
      <HeadHelmet data={strapiSsNews.MetaData} />
      <Layout>
        <StyledBackground
          img={strapiSsNews.image.childImageSharp.fluid}
          title={strapiSsNews.title}
          titleWidth="100%"
        />
        <br />
        <div className={'SharedEl__textContent--left'}>
          <ReactMarkdown>{strapiSsNews.content}</ReactMarkdown>
        </div>
      </Layout>
    </>
  );
};

export default NewsSlug;

export const query = graphql`
  query NewsSlug($id: String!) {
    strapiSsNews(id: { eq: $id }) {
      id
      MetaData {
        title
        metaKeywords
        metaDescription
      }
      title
      content
      slug
      published
      image {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
